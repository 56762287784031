import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/dashboard',
      name: 'home',
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/calendar/Calendar.vue'),
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('@/views/account/Account.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Account',
        breadcrumb: [
          {
            text: 'Quota',
            active: true,
          },
        ],
      },
    },
    {
      path: '/support',
      name: 'support',
      component: () => import('@/views/support/Support.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Support',
        breadcrumb: [
          {
            text: 'Contact Us',
            active: true,
          },
        ],
      },
    },
    {
      path: '/docs',
      name: 'docs',
      component: () => import('@/views/support/Docs.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Docs',
        breadcrumb: [
          {
            text: 'Docs',
            active: true,
          },
        ],
      },
    },
    {
      path: '/docs/:slug',
      name: 'doc',
      component: () => import('@/views/support/Doc.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Docs',
        breadcrumb: [
          {
            text: 'Docs',
            to: '/docs',
          },
          {
            text: 'Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: () => import('@/views/account/Checkout.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Checkout',
      },
    },
    {
      path: '/subscription',
      name: 'subscription',
      component: () => import('@/views/account/Subscription.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Subscription',
        breadcrumb: [
          {
            text: 'Plan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/channels',
      name: 'channels',
      component: () => import('@/views/channels/Channels.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Channels',
        breadcrumb: [
          {
            text: 'channels',
            active: true,
          },
        ],
      },
    },
    {
      path: '/posts',
      name: 'posts',
      component: () => import('@/views/posts/Posts.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Posts',
        breadcrumb: [
          {
            text: 'posts',
            active: true,
          },
        ],
      },
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/posts/Calendar.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Posts',
        breadcrumb: [
          {
            text: 'posts',
            active: true,
          },
        ],
      },
    },
    {
      path: '/brands',
      name: 'brands',
      component: () => import('@/views/brands/Brands.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Branded Content',
        breadcrumb: [
          {
            text: 'Brands',
            active: true,
          },
        ],
      },
    },
    {
      path: '/members',
      name: 'members',
      component: () => import('@/views/teams/Members.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Team Collaboration',
        breadcrumb: [
          {
            text: 'Members',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ai-auto-reply-comment',
      name: 'ai-auto-reply-comment',
      component: () => import('@/views/intents/Intents.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'AI Auto Reply Comment',
        breadcrumb: [
          {
            text: 'Intents',
            active: true,
          },
        ],
      },
    },
    {
      path: '/media-library',
      name: 'media-library',
      component: () => import('@/views/tools/MediaLibrary.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Tools',
        breadcrumb: [
          {
            text: 'Media Library',
            active: true,
          },
        ],
      },
    },
    {
      path: '/link-shortener',
      name: 'link-shortener',
      component: () => import('@/views/tools/LinkShortener.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Tools',
        breadcrumb: [
          {
            text: 'Link Shortener',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages',
      name: 'pages',
      component: () => import('@/views/tools/Pages.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Tools',
        breadcrumb: [
          {
            text: 'Link In Bio',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages/:id',
      name: 'page-details',
      component: () => import('@/views/tools/Page.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Tools',
        navActiveLink: 'pages',
        breadcrumb: [
          {
            text: 'Link In Bio',
            active: false,
            to: { name: 'pages' }
          },
          {
            text: 'Edit',
            active: true
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/',
      name: 'login-home',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verify',
      name: 'verify',
      component: () => import('@/views/Verify.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})


router.beforeEach((to, _, next) => {
  // handle access
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.auth.isAuthenticated) {
    return next({name: 'login'})
  } else {
    next()
  }
})


// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
