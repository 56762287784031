export default {
    namespaced: true,
    state: {
        isAuthenticated: false,
        token: '',
        user: {
            id: 0,
            email: '',
            full_name: '',
            account: {
                id: 0,
                plan: {
                    id: 0,
                    name: "",
                    price: 0
                },
                posts_quota_monthly: 0,
                comments_quota_monthly: 0,
                channels_quota_monthly: 0,
                brands_quota_monthly: 0,
                members_quota_monthly: 0,
                plan_status: "",
                plan_end_date: null,
                stripe_customer_id: null,
                stripe_subscription_id: null,
                created_at: "",
                updated_at: ""
            },
            current_brand: 0,
            current_brand_name: '',
            current_brand_image: '',
            current_brand_permission: '',
            current_brand_signature: '',
            current_brand_timezone: 'GMT',
        },
    },
    getters: {
    },
    mutations: {
        initializeStore(state) {
            if (localStorage.getItem('token')) {
                state.token = localStorage.getItem('token')
                state.isAuthenticated = true
                state.user.email = localStorage.getItem('email')
                state.user.full_name = localStorage.getItem('fullname')
                state.user.id = localStorage.getItem('userid')
                state.user.account.id = localStorage.getItem('account_id')
                state.user.account.posts_quota_monthly = localStorage.getItem('posts_quota_monthly')
                state.user.account.comments_quota_monthly = localStorage.getItem('comments_quota_monthly')
                state.user.account.channels_quota_monthly = localStorage.getItem('channels_quota_monthly')
                state.user.account.brands_quota_monthly = localStorage.getItem('brands_quota_monthly')
                state.user.account.members_quota_monthly = localStorage.getItem('members_quota_monthly')
                state.user.account.plan.id = localStorage.getItem('planid')
                state.user.account.plan.name = localStorage.getItem('planname')
            } else {
                state.token = ''
                state.isAuthenticated = false
                state.user.id = 0
                state.user.email = ''
                state.user.full_name = ''
                state.user.phone_number = ''
                state.user.account.id = 0
                state.user.account.plan.id = 0
                state.user.account.plan.name = ''
            }

            if (localStorage.getItem('current_brand_id')) {
                state.user.current_brand = localStorage.getItem('current_brand_id')
                state.user.current_brand_name = localStorage.getItem('current_brand_name')
                state.user.current_brand_permission = localStorage.getItem('current_brand_permission')
                state.user.current_brand_image = localStorage.getItem('current_brand_image')
                state.user.current_brand_signature = localStorage.getItem('current_brand_signature')
                state.user.current_brand_timezone = localStorage.getItem('current_brand_timezone')
            }
        },
        setToken(state, token) {
            state.token = token
            state.isAuthenticated = true
        },
        removeToken(state) {
            state.token = ''
            state.isAuthenticated = false
        },
        setUser(state, user) {
            state.user.id = user.id
            state.user.email = user.email
            state.user.full_name = user.full_name
        },
        setBrand(state, brand) {
            console.log(brand);
            let image_url = ''

            if (brand.image && brand.image != null){
                if (brand.image.includes('http')) {
                    image_url = brand.image
                } else {
                    image_url = `${process.env.VUE_APP_BACKEND_BASE_URL}${brand.image}`
                }
            }

            state.user.current_brand = brand.id
            state.user.current_brand_name = brand.name
            state.user.current_brand_permission = brand.permission
            state.user.current_brand_image = image_url
            state.user.current_brand_signature = brand.signature

            localStorage.setItem('current_brand_id', brand.id)
            localStorage.setItem('current_brand_name', brand.name)
            localStorage.setItem('current_brand_permission', brand.permission)
            localStorage.setItem('current_brand_image', image_url)
            localStorage.setItem('current_brand_signature', brand.signature)
            localStorage.setItem('current_brand_timezone', brand.timezone)
        },
        setPlan(state, plan){
            state.user.account.plan.id = plan.id
            state.user.account.plan.name = plan.name
        }
    },
    actions: {},
}